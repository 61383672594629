import { useContext, useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axiosInstance from "../axios.instance";
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { UserContext } from "../context/UserProvider";
import { useConfig } from "../configuration/useConfig";
import { SSOInfo } from "../components/wizard/types";

/**
 *
 * @description requires a token
 */
const SSO = () => {
  const [error, setError] = useState(false);
  const { setUser } = useContext(UserContext);
  const { config } = useConfig();
  const history = useHistory();
  type SSOResponse = {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    email?: string;
    zip?: string;
    // TODO - standardize and pick a name for this field. There is inconsistency here, calling it "corporationCode" on the backend but "corpCode"
    // within this UI. Due to time constraints of getting a delayed deployment out into QA, I am making temporary decision to keep them inconsistent
    // and just map corpCode to corporationCode in the redirection after the POST /sso API call in the useEffect logic below.
    subscriberId: string;
    isDependent: boolean;
    groupId: string;
    createLogin: boolean;
    clientSpecificData: {
      clientCode: string;
      corporationCode: string;
      groupNumber: string;
    };
  };
  const {
    loginWithRedirect,
    getAccessTokenSilently,
    isAuthenticated,
    user,
    logout,
  } = useAuth0();
  const attemptedRef = useRef(false);

  // Silent authentication or login redirect
  useEffect(() => {
    const checkAuthentication = async () => {
      if (attemptedRef.current) return;
      try {
        await getAccessTokenSilently();
        attemptedRef.current = true;
      } catch (error) {
        console.error("Silent authentication failed:", error);
        loginWithRedirect();
      }
    };
    checkAuthentication();
  }, [getAccessTokenSilently, loginWithRedirect]);

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        try {
          const token = await getAccessTokenSilently({ cacheMode: "off" });
          axiosInstance
            .post<SSOResponse | string>(
              "/sso",
              {},
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((response) => {
              if (response.status === 200) {
                if (response.data === "") {
                  // person id is populated and should get the token again from auth0 without using cache
                  getAccessTokenSilently({ cacheMode: "off" }).then(() => {
                    history.push("/account");
                  });
                } else {
                  const data = response.data as SSOResponse;
                  setUser({ isSSO: true });

                  const dob = new Date(data.dateOfBirth || 0);
                  const year = dob.getFullYear().toString();
                  const month = ("0" + (dob.getMonth() + 1))
                    .slice(-2)
                    .toString();
                  const day = ("0" + dob.getUTCDate()).slice(-2).toString();
                  const ssoInfo: SSOInfo = {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    day: day,
                    month: month,
                    year: year,
                    subscriberId: data.subscriberId,
                    corporationCode: data.clientSpecificData.corporationCode,
                    groupNumber: data.clientSpecificData.groupNumber,
                  };
                  localStorage.setItem("ssoInfo", JSON.stringify(ssoInfo));
                  if (config.client === "hcsc") {
                    history.push("/");
                  } else {
                    history.push(
                      `/enroll/eligibility?isSSO=true&firstName=${data.firstName}&lastName=${data?.lastName}&dob=${data.dateOfBirth}&email=${data.email}&subscriberId=${data.subscriberId}&zip=${data.zip}`
                    );
                  }
                }
              }
            })
            .catch((e) => {
              logout();
              history.push("/");
              console.log({ e });
            });
        } catch (e) {
          loginWithRedirect();
          console.error(e);
          setError(true);
        }
      })();
    }
  }, [isAuthenticated, getAccessTokenSilently, user]);

  if (error) throw new Error();

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spinner animation="border" role="status" aria-hidden="true" />
    </div>
  );
};

export default SSO;
